<template>
  <div class="mt-1">
    <h1 class="color-secondary ">Mis Pedidos</h1>
  </div>
  <div class="item mt-3">
    <div class="flex justify-space-between align-center sm-direction-column sm-align-start sm-full ">
      <h5 class="color-primary mb-0 mt-0">
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 0H1V2H17V0ZM18 10V8L17 3H1L0 8V10H1V16H11V10H15V16H17V10H18ZM9 14H3V10H9V14Z" fill="#F36E44"/>
        </svg>
        <span class="ml-1">El Califa Condesa</span>
      </h5>
      <router-link class="btn-secondary btn-alt sm-mt-1" to="/account/order">Ver Pedido</router-link>
    </div>
    <p class="alt color-40">Artículos de $179 <span class="ml-1 mr-1">|</span> Hoy, Miércoles 05 de Enero, 2021</p>
    <div class="flex align-center mt-2">
      <img class="round-4 mr-4" src="https://via.placeholder.com/43x43?text=adomicilio1" alt="Adomicilio">
      <p class="label color-primary mr-4">1</p>
      <p class="label color-secondary">Platillo de Prueba</p>
    </div>
    <div class="flex align-center mt-1 mb-2">
      <img class="round-4 mr-4" src="https://via.placeholder.com/43x43?text=adomicilio1" alt="Adomicilio">
      <p class="label color-primary mr-4">1</p>
      <p class="label color-secondary">Platillo de Prueba</p>
    </div>
    <hr class="bg-color-20">
  </div>
  <div class="item mt-3">
    <div class="flex justify-space-between align-center sm-direction-column sm-align-start sm-full ">
      <h5 class="color-primary mb-0 mt-0">
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 0H1V2H17V0ZM18 10V8L17 3H1L0 8V10H1V16H11V10H15V16H17V10H18ZM9 14H3V10H9V14Z" fill="#F36E44"/>
        </svg>
        <span class="ml-1">El Califa Condesa</span>
      </h5>
      <router-link class="btn-secondary btn-alt sm-mt-1" to="/">Volver a Pedir</router-link>
    </div>
    <p class="alt color-40">Artículos de $179 <span class="ml-1 mr-1">|</span> Hoy, Miércoles 05 de Enero, 2021
      <a class="ml-4" href="/"><u>Ver recibo</u></a></p>
    <div class="flex align-center mt-2">
      <img class="round-4 mr-4" src="https://via.placeholder.com/43x43?text=adomicilio1" alt="Adomicilio">
      <p class="label color-primary mr-4">1</p>
      <p class="label color-secondary">Platillo de Prueba</p>
    </div>
    <div class="flex align-center mt-1 mb-2">
      <img class="round-4 mr-4" src="https://via.placeholder.com/43x43?text=adomicilio1" alt="Adomicilio">
      <p class="label color-primary mr-4">1</p>
      <p class="label color-secondary">Platillo de Prueba</p>
    </div>
    <hr class="bg-color-20">
  </div>
</template>
